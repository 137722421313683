/*eslint-disable */
// 消息推送模块
// 包含base64和socket.io模块
import 'push';
// vue 公共模块
import 'vue-style-loader/lib/addStylesClient';
import 'vue-style-loader/lib/listToStyles';
import 'css-loader/lib/url/escape.js';
// md5
import 'blueimp-md5';
// fetch模块
import 'fetch';
/*eslint-enable */
