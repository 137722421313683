import Vue from 'vue';
import VueI18n from 'vue-i18n';
import dateFormat from 'kernel/js/dateFormat';
import { getLang, langList } from 'utils/lang';

Vue.use(VueI18n);
const curLang = getLang() || 'en';
export const i18n = new VueI18n({
	locale: curLang, // 设置语言环境
	fallbackLocale: 'en'
});

window.lang = curLang;

function setI18nLanguage (lang) {
	// 获取翻译文件后的一些配置操作， dateformat的配置也放到这里
	i18n.locale = lang;
  // document.querySelector('html').setAttribute('lang', lang)
	dateFormat.i18n = {
		// 对周命名做一下国际化
		dayNames: [
			i18n.t('sunday:label'), i18n.t('monday:label'), i18n.t('tuesday:label'), i18n.t('wednesday:label'), i18n.t('thursday:label'), i18n.t('friday:label'), i18n.t('saturday:label')
		],
		dayNamesShort: [
			i18n.t('sun:label'), i18n.t('mon:label'), i18n.t('tue:label'), i18n.t('wed:label'), i18n.t('thu:label'), i18n.t('fri:label'), i18n.t('sat:label')
		]
	};
	return lang;
}

const langFileMap = {
	// en: 'en',
	// zh_CN: 'zh-CN',
	// ko: 'ko',
	// ja: 'ja-JP',
	// zht: 'zh-Hant',
	// km: 'km',
	// vi: 'vi-VN',
	// th: 'th-TH',
	// ms: 'ms-MY',
	// 'in': 'id-ID',
	// pt: 'pt-BR',
};
langList.forEach(item => {
	langFileMap[item.name] = item.fileName;
});
// 后台和雷达那边的语言标识和通用不太一样， 取标准文件时转一下
export function loadLanguageAsync () {
	return import(/* webpackChunkName: "[request]" */ `./lang/${langFileMap[curLang]}`).then(msgs => {
		i18n.setLocaleMessage(curLang, msgs.default);
		return setI18nLanguage(curLang);
	});
}

export default {
	i18n,
	loadLanguageAsync
};
