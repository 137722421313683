import URL from 'packages/fe.url';
import { LS } from 'storage';
import opConfig from '../../modules/common-wap/config/opConfig';

// export const langList = ['en', 'zh_CN'];
export const langList = [
	{
		label: 'English',
		name: 'en',
		fileName: 'en',
		countrys: [''],
		notBelongOp: ['sport88'],
	}, {
		label: '中文',
		name: 'zh_CN',
		fileName: 'zh-CN',
		countrys: ['ch', 'trc'],
		notBelongOp: [
			'lottonowicebrl',
			'lottonowkoousdt',
			'lottonowkonekrw',
			'lottonowponeclubphp',
			'lottonowmoneclubmxn',
			'lottonow12winmyr',
			'lottonowioneclubidr',
			'lottonowwinspaceksh',
			'lottonowarsbetars',
			'lottonowbrl'
		],
	}, {
		label: 'Tiếng việt',
		name: 'vi',
		fileName: 'vi-VN',
		countrys: ['vt'],
		notBelongOp: [
			'lottonowicebrl',
			'lottonowkoousdt',
			'lottonowkonekrw',
			'lottonowponeclubphp',
			'lottonowmoneclubmxn',
			'lottonow12winmyr',
			'lottonowioneclubidr',
			'lottonowwinspaceksh',
			'lottonowarsbetars',
			'lottonowbrl'
		],
	}, {
		label: 'Bahasa Indonesia',
		name: 'idr',
		fileName: 'id-ID',
		countrys: ['idr'],
		notBelongOp: [
			'lottonowicebrl',
			'lottonowkoousdt',
			'lottonowkonekrw',
			'lottonowponeclubphp',
			'lottonowmoneclubmxn',
			'lottonow12winmyr',
			'lottonowwinspaceksh',
			'lottonowarsbetars',
			'lottonowbrl'
		],
	}, {
		label: 'Português',
		name: 'pt',
		fileName: 'pt-BR',
		countrys: ['br'],
		notBelongOp: [
			'lottonowkonekrw',
			'lottonowponeclubphp',
			'lottonowmoneclubmxn',
			'lottonow12winmyr',
			'lottonowwinspaceksh',
			'lottonowarsbetars',
			'lottonowioneclubidr'
		],
	}, {
		label: '한국인',
		name: 'ko',
		fileName: 'ko',
		countrys: ['kr'],
		notBelongOp: [
			'lottonowicebrl',
			'lottonowkoousdt',
			'lottonowponeclubphp',
			'lottonowmoneclubmxn',
			'lottonow12winmyr',
			'lottonowwinspaceksh',
			'lottonowarsbetars',
			'lottonowioneclubidr',
			'lottonowbrl'
		],
	}, {
		label: 'Philippines',
		name: 'ph',
		fileName: 'ph',
		countrys: ['ph'],
		notBelongOp: [
			'lottonowicebrl',
			'lottonowkoousdt',
			'lottonowkonekrw',
			'lottonowmoneclubmxn',
			'lottonow12winmyr',
			'lottonowwinspaceksh',
			'lottonowarsbetars',
			'lottonowioneclubidr',
			'lottonowbrl'
		],
	}, {
		label: 'Español', // 墨西哥 - 西班牙语言
		name: 'es',
		fileName: 'es',
		countrys: ['mx'],
		notBelongOp: [
			'lottonowicebrl',
			'lottonowkoousdt',
			'lottonowkonekrw',
			'lottonowponeclubphp',
			'lottonowioneclubidr',
			'lottonow12winmyr',
			'lottonowwinspaceksh',
			'lottonowbrl'
		],
	}
];
// , {
// 	label: '한국어',
// 	name: 'ko'
// }, {
// 	label: '日本語',
// 	name: 'ja'
// }, {
// 	label: '繁體中文',
// 	name: 'zht'
// }, {
// 	label: 'ខ្មែរ',
// 	name: 'km'
// }, {
// 	label: 'Tiếng việt',
// 	name: 'vi'
// }, {
// 	label: 'ไทย',
// 	name: 'th'
// }, {
// 	label: 'Melayu',
// 	name: 'ms'
// }, {
// 	label: 'Bahasa indonesia',
// 	name: 'in'
// }];

const langMap = {};
langList.forEach(item => {
	langMap[item.name] = item;
});

// 获取指定语言，包括currentlang
export function getLangList() {
	const curLang = window.lang;
	let newLangList = [];
	langList.forEach((lang, i) => {
		if (lang.name === curLang) {
			newLangList.push(langList[i]);
		}
	});
	const elseLangList = langList.filter(item => !item.notBelongOp.includes(window.op));

	newLangList = newLangList.concat(elseLangList);
	const obj = {};
	newLangList = newLangList.reduce((prev, cur) => {
		obj[cur.name] ? '' : obj[cur.name] = true && prev.push(cur);
		return prev;
	}, []);
	return newLangList;
}

export function getLang() {
	let lang = URL.getPara('lang') || LS.get('lang');
	// 判断当前是否不属于该op，如果不属于则按默认语言展示
	let notBelongOp = [];
	langList.forEach((l, i) => {
		if (l.name === lang) {
			notBelongOp = l.notBelongOp;
		}
	});
	if (notBelongOp.includes(window.op)) { // 如果该语言不归属在此op，取默认值
		lang = null;
	} else { // 按原来的常规流程走
		if (window.lang) {
			return window.lang;
		}
	}

	// op为sport88 默认zh_CN 中文
	if (!lang && opConfig === 'sport88') {
		lang = 'zh_CN';
	}
	// op为24sport 默认en 英文
	if (!lang && opConfig === '24sport') {
		lang = 'en';
	}
	// op为lottonowkoousdt 默认en 英文
	if (!lang && opConfig === 'lottonowkoousdt') {
		lang = 'en';
	}
	// lottonowkonekrw 默认ko 韩文
	if (!lang && opConfig === 'lottonowkonekrw') {
		lang = 'ko';
	}
	// 如果op为lottonowbrl 默认pt葡萄牙语
	if (!lang && opConfig === 'lottonowbrl') {
		lang = 'pt';
	}
	// lottonowicebrl 默认pt葡萄牙语
	if (!lang && ['lottonowicebrl'].includes(window.op)) {
		lang = 'pt';
	}
	// lottonowponeclubphp 默认pt菲律宾
	if (!lang && ['lottonowponeclubphp'].includes(window.op)) {
		lang = 'en';
	}
	// lottonowmoneclubmxn 默认es西班牙
	if (!lang && ['lottonowmoneclubmxn', 'lottonowarsbetars'].includes(window.op)) {
		lang = 'es';
	}

	// lottonowioneclubidr 默认idr 印尼语
	if (!lang && ['lottonowioneclubidr'].includes(window.op)) {
		lang = 'idr';
	}

	// tidemo 默认en 英语
	if (!lang && ['tidemo'].includes(window.op)) {
		lang = 'en';
	}

	if (!lang || !langMap[lang]) {
		lang = 'en';
	}
	// 存储lang
	LS.set('lang', lang);
	window.lang = lang;
	return lang;
}

export default {
	langList,
	getLang
};
